@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.search-input {
  position: relative;
  background-color: transparent;
  height: 100%;
  width: 100%;
  padding-left: 20px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  gap: 10px;

  &--close-search-input-field {
    @include tablet {
      pointer-events: none;
    }

    @include mobile {
      pointer-events: none;
    }
  }

  @include mobile {
    position: static;
    padding-right: 0;
    &.focus {
      padding-left: 10px;
    }
  }
  &-icon {
    min-width: 38px;
    width: 38px;
    height: 38px;
    cursor: pointer;

    @include tablet {
      pointer-events: initial;
    }

    @include mobile {
      pointer-events: initial;
    }

    & svg {
      width: 100%;
      height: 100%;
      fill: white;
    }
    &-clear {
      min-width: 30px;
      width: 30px;
      height: 20px;
      fill: white;
      cursor: pointer;
      &.desktop {
        width: 17px;
        height: 17px;
        min-width: 17px;
        &:hover {
          fill: white;
        }
      }
    }
    &-back {
      min-width: 30px;
      width: 30px;
      height: 30px;

      & svg {
        fill: white;
      }
    }
  }
  &-text {
    width: 100%;
    color: white;
    @include Body-Regular-S;

    &--plain {
      color: $black;
    }

    &-wrapper {
      width: 100%;

      &--plain {
        & input {
          &::placeholder {
            color: $black;
          }
        }
      }

      & input {
        &::placeholder {
          color: white;
        }
      }

      &--plain {
        & input {
          &::placeholder {
            color: $gray-light;
          }
        }
      }
    }
  }
  &__auto-complete {
    background-color: $white;
    position: absolute;
    border: $gray-feather 1px solid;
    z-index: 2;
    top: calc(100% + 13px);
    left: 0;
    width: 100%;

    @include tablet {
      top: 100%;
    }

    @include mobile {
      top: 100%;
      min-height: 100vh;
      border: none;
      border-top: $gray-feather 1px solid;
    }

    &-history {
      padding: 20px 20px 30px 20px;
      background-color: $gray-very-light;
      overflow-y: auto;
      max-height: 250px;
      @include mobile {
        height: 100vh;
        max-height: none;
      }
      &-title {
        padding: 10px 0;
        @include Body-Regular-S;
        color: $gray-dim;
      }
    }

    &-mask {
      position: absolute;
      top: 70px;
      left: 0;
      width: 100vw;
      height: calc(100vh - 70px);
      overflow-x: hidden;
      overflow-y: auto;
      background: rgba(8, 8, 8);
      transition: all 0.2s ease-in-out;
      pointer-events: none;
      z-index: 1;
      opacity: 0;
      cursor: auto;
      &.active {
        opacity: 0.4;
        pointer-events: all;
      }
    }
  }
}
