@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.keyword-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  &__heading {
    padding: 10px 0;
    &-title {
      color: $gray-dim;
      @include Body-Regular-S;
    }
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    &-item {
      padding: 8px 14px;
      min-width: 56px;
      max-width: 140px;
      border-radius: 50px;
      background-color: $white;
      display: flex;
      justify-content: center;
      cursor: pointer;
      &-text {
        word-break: break-all;
        color: $black;
        @include text-ellipsis;
        text-align: center;
        @include Body-Regular-S;
      }
    }
  }
}
