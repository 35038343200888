@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.option {
  background-color: $white;
  display: flex;
  gap: 4px;
  cursor: pointer;
  padding: 10px;
  color: $black;
  @include Body-Regular-S;
  &.active {
    background-color: $gray-very-light;
  }
  &:hover {
    background-color: $gray-very-light;
  }
}
