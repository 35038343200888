@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: $gray-very-light;
  z-index: $page-loading-index;

  &[data-is-full='false'] {
    width: 80px;
    height: 80px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba($black, 0.6);
    border-radius: 10px;
  }

  &-lottie {
    width: 80px;
    height: 80px;
  }
}
