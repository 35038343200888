@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.popup {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10001;

  &[data-is-open='false'] {
    transform: translate3d(0px, 100%, 0px);
  }

  &.isDrawer {
    @include mobile {
      height: 100%;
      justify-content: flex-end;
      transition: transform 0.5s;
    }
  }

  &-background {
    width: 100%;
    height: 100vh;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: -1;
    background: rgba(8, 8, 8, 0.6);

    &.isDrawer {
      @include mobile {
        background: transparent;
      }
    }
  }

  &-wrapper {
    width: auto;
    height: auto;
    max-height: 80%;
    min-width: 360px;
    max-width: 600px;
    padding: 20px;
    margin: 0px 20px;
    background-color: $gray-very-light;
    position: relative;
    border-radius: 10px;

    @include mobile {
      width: 100%;
      max-width: 320px;
      padding: 20px 10px;
      transform: translate3d(0px, 100%, 0px);
      transition: transform 0.5s;

      &[data-is-open='true'] {
        transform: translate3d(0, 0, 0);
      }
    }

    &.isDrawer {
      @include mobile {
        max-width: 100%;
        height: 100%;
        max-height: 460px;
        margin: 0px;
        border-radius: 10px 10px 0px 0px;
      }
    }

    &.isHeightAuto {
      @include mobile {
        height: auto;
      }
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-title {
        margin-bottom: 6px;
        color: $black;
        @include Body-Bold-M;
        text-align: center;
      }
    }

    &__body {
      width: 100%;
      max-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__action {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      &-button {
        &:not(:last-of-type) {
          margin-right: 10px;
        }
      }
    }

    &__close {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: rgba($black, 0.4);
      position: absolute;
      left: 50%;
      bottom: -40px;
      transform: translateX(-50%);
      fill: $white;
      cursor: pointer;

      & svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
