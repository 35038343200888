@import "./variables.scss";
@import "./mixins.scss";

@mixin fade-motion($className, $keyframeName) {
  @include make-motion($className, $keyframeName);
  .#{$className}-enter,
  .#{$className}-appear {
    opacity: 0;
    animation-timing-function: linear;
  }
  .#{$className}-leave {
    animation-timing-function: linear;
  }
}

@include fade-motion(fade, tmFade);

@keyframes tmFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes tmFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
