@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
// themes
$themes: (
  transparent-black: (
    background-color: rgba(8, 8, 8, 0.4),
    color: white,
  ),
  gray: (
    background-color: rgba(8, 8, 8, 0.1),
    color: $gray-dim,
  ),
);

.insight {
  @each $theme, $styles in $themes {
    &[data-theme='#{$theme}'] {
      background-color: map-get($styles, background-color);
      color: map-get($styles, color);
    }
  }

  border-radius: 100px;
  display: inline-flex;
  align-items: center;

  &--empty {
    background-color: initial !important;
    color: initial !important;

    & .insight__header {
      padding: 1px;
    }

    & .insight__header-icon {
      width: 14px;
      height: 14px;
    }
  }

  &__header {
    display: inline-flex;
    align-items: center;
    border-radius: 100px;
    background: var(--insight-background-color);
    color: white;
    padding: 2px 6px 2px 8px;

    &-title {
      @include Body-Regular-XS;
      margin-right: 5px;
    }

    &-icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;

      &-lottie-wrapper {
        display: inline-flex;
        width: inherit;
        height: inherit;
      }
    }
  }

  &__content {
    margin-left: 4px;
    margin-right: 8px;
    display: inline-flex;
    align-items: center;
  }

  &__count {
    @include Body-Regular-XS;
    margin-right: 4px;
    padding-top: 2px;
  }

  &__status {
    @include Body-Regular-XS;
    padding-top: 2px;
  }

  &.canClick {
    cursor: pointer;
  }

  &.isCircle {
    padding: 2px;
    border-radius: 50%;
    background-color: $gray-background;
    min-height: 15px;
  }
}
