@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.link {
  width: fit-content;
  display: inherit;
  justify-content: inherit;
  align-items: inherit;
  position: relative;

  &-item {
    width: 100%;
    height: inherit;
    display: inherit;
    justify-content: inherit;
    align-items: inherit;
    cursor: pointer;

    &-tag {
      // TODO: 點不到 a tag 會無法右鍵另開，但現在單純點 a tag 或沒有 router.push，用 next/link 則會有 query 沒透傳的問題
      // width: 100%;
      // height: 100%;
      // position: absolute;
      // left: 0px;
      // top: 0px;
    }
  }
}
