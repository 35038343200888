@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
@font-face {
  font-family: 'fonts';
  src:  url('fonts/fonts.eot?j6ltxl');
  src:  url('fonts/fonts.eot?j6ltxl#iefix') format('embedded-opentype'),
    url('fonts/fonts.ttf?j6ltxl') format('truetype'),
    url('fonts/fonts.woff?j6ltxl') format('woff'),
    url('fonts/fonts.svg?j6ltxl#fonts') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'fonts' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clip:before {
  content: "\e9ca";
}
.icon-tag:before {
  content: "\e9cb";
}
.icon-cone:before {
  content: "\e9c4";
}
.icon-cylinder:before {
  content: "\e9c5";
}
.icon-cube:before {
  content: "\e9c6";
}
.icon-sector:before {
  content: "\e9c3";
}
.icon-down-round:before {
  content: "\e9bd";
}
.icon-head:before {
  content: "\e9be";
}
.icon-up-round:before {
  content: "\e9bf";
}
.icon-count-down:before {
  content: "\e9c9";
}
.icon-dice:before {
  content: "\e9c8";
}
.icon-interact-tools:before {
  content: "\e9c7";
}
.icon-close-round:before {
  content: "\e9bc";
}
.icon-error-round:before {
  content: "\e9ba";
}
.icon-hourglass:before {
  content: "\e9bb";
}
.icon-interact-disable:before {
  content: "\e9b8";
}
.icon-interact:before {
  content: "\e9b9";
}
.icon-download:before {
  content: "\e9b7";
}
.icon-tap2:before {
  content: "\e9b6";
}
.icon-clown:before {
  content: "\e9af";
}
.icon-down:before {
  content: "\e9b0";
}
.icon-offline:before {
  content: "\e9b1";
}
.icon-up:before {
  content: "\e9b2";
}
.icon-bottom-arrow:before {
  content: "\e9ae";
}
.icon-dictionary2:before {
  content: "\e9ad";
}
.icon-mic-setting:before {
  content: "\e9aa";
}
.icon-pencil3:before {
  content: "\e9a9";
}
.icon-speed-warn:before {
  content: "\e9a8";
}
.icon-empty:before {
  content: "\e9a7";
}
.icon-tip:before {
  content: "\e9a3";
}
.icon-setting:before {
  content: "\e9a4";
}
.icon-refresh1:before {
  content: "\e9a5";
}
// .icon-trophy:before {
//   content: "\e9a6";
// }
.icon-arrow-bottom:before {
  content: "\e9a2";
}
.icon-expand:before {
  content: "\e9a1";
}
.icon-clock-colorful .path1:before {
  content: "\e995";
  color: rgb(249, 219, 145);
}
.icon-clock-colorful .path2:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(239, 106, 106);
}
.icon-clock-colorful .path3:before {
  content: "\e997";
  margin-left: -1em;
  color: rgb(174, 240, 255);
}
.icon-clock-colorful .path4:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(81, 44, 86);
}
.icon-clock-colorful .path5:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(81, 44, 86);
}
.icon-horn .path1:before {
  content: "\e99a";
  color: rgb(192, 234, 255);
}
.icon-horn .path2:before {
  content: "\e99b";
  margin-left: -1em;
  color: rgb(192, 234, 255);
}
.icon-horn .path3:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(31, 135, 221);
}
.icon-horn .path4:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(31, 135, 221);
}
.icon-horn .path5:before {
  content: "\e99e";
  margin-left: -1em;
  color: rgb(31, 135, 221);
}
.icon-horn .path6:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(31, 135, 221);
}
.icon-horn .path7:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(31, 135, 221);
}
.icon-ide-authorized:before {
  content: "\e9b3";
}
.icon-ide:before {
  content: "\e9b4";
}
.icon-ide-authorized-disabled:before {
  content: "\e9b5";
}
.icon-person-profile:before {
  content: "\e993";
}
.icon-report:before {
  content: "\e991";
}
.icon-microphone:before {
  content: "\e990";
}
.icon-mic-disabled:before {
  content: "\e98f";
}
.icon-disable:before {
  content: "\e98e";
}
.icon-chat:before {
  content: "\e98c";
}
.icon-material:before {
  content: "\e98d";
}
.icon-speaker-off:before {
  content: "\e98a";
}
.icon-speaker-on:before {
  content: "\e98b";
}
.icon-speaker1:before {
  content: "\e992";
}
.icon-airplane:before {
  content: "\e988";
}
.icon-marker:before {
  content: "\e985";
}
// .icon-silent:before {
//   content: "\e986";
// }
.icon-user:before {
  content: "\e987";
}
.icon-speaker:before {
  content: "\e989";
}
.icon-move:before {
  content: "\e980";
}
.icon-prev-view:before {
  content: "\e981";
}
.icon-next-view:before {
  content: "\e982";
}
.icon-zoom-out:before {
  content: "\e983";
}
.icon-zoom-in:before {
  content: "\e984";
}
.icon-magic-face:before {
  content: "\e97e";
}
.icon-delete:before {
  content: "\e97d";
}
.icon-speaking-ratio:before {
  content: "\e97f";
}
.icon-tips:before {
  content: "\e994";
}
.icon-ring:before {
  content: "\e97c";
}
.icon-learning-note_green:before {
  content: "\e97b";
}
.icon-net-error:before {
  content: "\e973";
}
// .icon-net-unstable:before {
//   content: url(../../assets/images/icon-signal-warn.svg)
// }
.icon-clock:before {
  content: "\e975";
}
.icon-coffee:before {
  content: "\e976";
}
.icon-classic:before {
  content: "\e974";
}
.icon-person:before {
  content: "\e97a";
}
.icon-network:before {
  content: "\e979";
}
.icon-dictionary-light:before {
  content: "\e977";
}
.icon-dictionary:before {
  content: "\e978";
}
.icon-message:before {
  content: "\e971";
}
.icon-compass:before {
  content: "\e970";
}
.icon-right-triangle:before {
  content: "\e914";
}
.icon-dashed:before {
  content: "\e96a";
}
.icon-ratate:before {
  content: "\e96c";
}
.icon-computer:before {
  content: "\e911";
}
.icon-people1:before {
  content: "\e912";
}
.icon-shudown:before {
  content: "\e95b";
}
.icon-change:before {
  content: "\e96b";
}
.icon-fullscreen:before {
  content: "\e965";
}
.icon-nomalscreen:before {
  content: "\e966";
}
.icon-screenshare-off:before {
  content: "\e967";
}
.icon-screenshare-on:before {
  content: "\e968";
}
.icon-screenshare:before {
  content: "\e969";
}
.icon-profile:before {
  content: "\e962";
}
.icon-note2:before {
  content: "\e963";
}
.icon-note1:before {
  content: "\e964";
}
.icon-out:before {
  content: "\e972";
}
.icon-mic-on:before {
  content: "\e96f";
}
.icon-mic-off:before {
  content: "\e961";
}
.icon-camera-disable:before {
  content: "\e95c";
}
.icon-mic-disable:before {
  content: "\e95d";
}
.icon-math-add:before {
  content: "\e900";
}
.icon-down-load2:before {
  content: "\e901";
}
.icon-down-load1:before {
  content: "\e902";
}
.icon-close:before {
  content: "\e903";
}
.icon-reduce:before {
  content: "\e904";
}
.icon-done:before {
  content: "\e905";
}
.icon-play:before {
  content: "\e906";
}
.icon-pause:before {
  content: "\e907";
}
.icon-sounds:before {
  content: "\e908";
}
.icon-animal:before {
  content: "\e909";
}
.icon-car:before {
  content: "\e90a";
}
.icon-classical:before {
  content: "\e90b";
}
.icon-emoji:before {
  content: "\e90c";
}
.icon-lion:before {
  content: "\e90d";
}
.icon-sport:before {
  content: "\e90e";
}
.icon-sigh:before {
  content: "\e90f";
}
.icon-stop:before {
  content: "\e910";
}
.icon-facefocus:before {
  content: "\e913";
}
.icon-pen-off:before {
  content: "\e96e";
}
.icon-pen-on:before {
  content: "\e915";
}
.icon-marks-left:before {
  content: "\e916";
}
.icon-marks-right:before {
  content: "\e917";
}
.icon-tip-left:before {
  content: "\e918";
}
.icon-tip-right:before {
  content: "\e919";
}
.icon-right-round:before {
  content: "\e91a";
}
.icon-time-round:before {
  content: "\e91b";
}
.icon-sound2:before {
  content: "\e91c";
}
.icon-cow:before {
  content: "\e960";
}
.icon-search:before {
  content: "\e95e";
}
// .icon-searchpic:before {
//   content: "\e95f";
// }
// .icon-block:before {
//   content: "\e91d";
// }
.icon-unlock:before {
  content: "\e91e";
}
.icon-people:before {
  content: "\e91f";
}
.icon-round:before {
  content: "\e920";
}
.icon-rectangle:before {
  content: "\e921";
}
.icon-triangle:before {
  content: "\e922";
}
.icon-round-full:before {
  content: "\e923";
}
.icon-straight:before {
  content: "\e924";
}
.icon-arrow:before {
  content: "\e925";
}
.icon-camera-off:before {
  content: "\e926";
}
.icon-camera-on:before {
  content: "\e927";
}
.icon-helper:before {
  content: "\e928";
}
.icon-add-round:before {
  content: "\e929";
}
.icon-add-blank:before {
  content: "\e92a";
}
.icon-add:before {
  content: "\e92b";
}
.icon-pic:before {
  content: "\e92c";
}
.icon-del:before {
  content: "\e92d";
}
.icon-refresh:before {
  content: "\e930";
}
// .icon-unblock:before {
//   content: "\e932";
// }
// .icon-clap:before {
//   content: "\e933";
// }
// .icon-handup:before {
//   content: "\e934";
// }
.icon-blow-up:before {
  content: "\e935";
}
.icon-blow-down:before {
  content: "\e936";
}
// .icon-music:before {
//   content: "\e937";
// }
.icon-next:before {
  content: "\e938";
}
.icon-next-all:before {
  content: "\e939";
}
.icon-pre:before {
  content: "\e93a";
}
.icon-pre-all:before {
  content: "\e93b";
}
// .icon-rank:before {
//   content: "\e93c";
// }
.icon-star:before {
  content: "\e93d";
}
.icon-thumbup:before {
  content: "\e93e";
}
.icon-video:before {
  content: "\e93f";
}
// .icon-vote:before {
//   content: "\e940";
// }
.icon-more:before {
  content: "\e941";
}
.icon-backward:before {
  content: "\e9ab";
}
.icon-forward:before {
  content: "\e9ac";
}
.icon-A:before {
  content: "\e92e";
}
.icon-arrow-right:before {
  content: "\e92f";
}
.icon-baton:before {
  content: "\e931";
}
.icon-arrow-left:before {
  content: "\e94e";
}
.icon-round2:before {
  content: "\e94f";
}
.icon-del2:before {
  content: "\e950";
}
.icon-eraser:before {
  content: "\e951";
}
.icon-line:before {
  content: "\e952";
}
.icon-maker-pen:before {
  content: "\e953";
}
.icon-pencil:before {
  content: "\e954";
}
// .icon-pic-smile:before {
//   content: "\e955";
// }
.icon-rectangle2:before {
  content: "\e956";
}
.icon-redo:before {
  content: "\e957";
}
.icon-sound:before {
  content: "\e958";
}
.icon-triangle2:before {
  content: "\e959";
}
.icon-undo:before {
  content: "\e95a";
}
.icon-Aa:before {
  content: "\e96d";
}
.icon-A2:before {
  content: "\e942";
}
.icon-arrow-right2:before {
  content: "\e943";
}
.icon-arrow-left-bold:before {
  content: "\e944";
}
.icon-circle2:before {
  content: "\e945";
}
.icon-delete2:before {
  content: "\e946";
}
.icon-eraser2:before {
  content: "\e947";
}
.icon-line2:before {
  content: "\e948";
}
.icon-maker-pen2:before {
  content: "\e949";
}
.icon-pencil2:before {
  content: "\e94a";
}
.icon-rectangle-bold:before {
  content: "\e94b";
}
.icon-triangle-bold:before {
  content: "\e94c";
}
.icon-Aa2:before {
  content: "\e94d";
}
