@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.footer {
  position: relative;
  padding: 14px 0;
  @include tablet {
    padding: 20px 0;
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 10px;
  }

  &__icon {
    width: 24px;
    height: 24px;
    & svg {
      width: 100%;
      height: 100%;
      fill: $gray-dim;
      &:hover {
        color: $pink-main;
        fill: $pink-main;
      }
    }
  }

  &__bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    @include mobile {
      align-items: flex-start;
    }

    &-copy {
      color: $gray-light;
    }
    &-description {
      color: $gray-light;
      display: flex;
      // not last margin right 11px
      > :not(:last-child) {
        margin-right: 11px;
      }
      @include mobile {
        flex-direction: column;
      }
      &-section {
        display: flex;
        gap: 11px;
      }
      &-email {
        color: $gray-light;
        text-decoration: underline;
      }
    }
  }
}

.container {
  @include container-hf;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mobile {
    align-items: flex-start;
  }
}
