@import "./variables.scss";
@import "./mixins.scss";

@mixin zoom-motion($className, $keyframeName, $duration: $animation-duration-base) {
  @include make-motion($className, $keyframeName);
  .#{$className}-enter,
  .#{$className}-appear {
    transform: scale(0);
    animation-timing-function: $ease-out-circ;
  }
  .#{$className}-leave {
    animation-timing-function: $ease-in-out-circ;
  }
}

@include zoom-motion(zoom, tmZoom);
@include zoom-motion(zoom-big, tmZoomBig);
@include zoom-motion(zoom-big-fast, tmZoomBig, $animation-duration-fast);
@include zoom-motion(zoom-up, tmZoomUp);
@include zoom-motion(zoom-center, tmZoomCenter);
@include zoom-motion(zoom-down, tmZoomDown);
@include zoom-motion(zoom-left, tmZoomLeft);
@include zoom-motion(zoom-right, tmZoomRight);

@keyframes tmZoomIn {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes tmZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0.2);
  }
}

@keyframes tmZoomBigIn {
  0% {
    opacity: 0;
    transform: scale(.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes tmZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(.8);
  }
}

@keyframes tmZoomCenterIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(.3);
  }
  100% {
    transform-origin: 50% 50%;
    transform: scale(1);
  }
}

@keyframes tmZoomCenterOut {
  0% {
   transform-origin: 50% 50%;
   transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(.8);
  }
}

@keyframes tmZoomUpIn {
  0% {
    opacity: 0;
    transform-origin: 50% 0%;
    transform: scale(.8);
  }
  100% {
    transform-origin: 50% 0%;
    transform: scale(1);
  }
}

@keyframes tmZoomUpOut {
  0% {
    transform-origin: 50% 0%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 0%;
    transform: scale(.8);
  }
}

@keyframes tmZoomLeftIn {
  0% {
    opacity: 0;
    transform-origin: 0% 50%;
    transform: scale(.8);
  }
  100% {
    transform-origin: 0% 50%;
    transform: scale(1);
  }
}

@keyframes tmZoomLeftOut {
  0% {
    transform-origin: 0% 50%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 50%;
    transform: scale(.8);
  }
}

@keyframes tmZoomRightIn {
  0% {
    opacity: 0;
    transform-origin: 100% 50%;
    transform: scale(.8);
  }
  100% {
    transform-origin: 100% 50%;
    transform: scale(1);
  }
}

@keyframes tmZoomRightOut {
  0% {
    transform-origin: 100% 50%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 100% 50%;
    transform: scale(.8);
  }
}

@keyframes tmZoomDownIn {
  0% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(.8);
  }
  100% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }
}

@keyframes tmZoomDownOut {
  0% {
    transform-origin: 50% 100%;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 100%;
    transform: scale(.8);
  }
}

