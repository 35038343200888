@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.social-media-list {
  margin: 20px 0 calc(20px + $ios-url-bar-height) 0;

  &__title {
    @include Body-Regular-M;
    color: $gray-dim;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }

  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__item {
    display: flex;
    width: 30px;
    height: 30px;

    &:not(:first-child) {
      margin-left: 30px;
    }

    & svg {
      width: 30px;
      height: 30px;
    }
  }
}
