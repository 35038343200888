@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.debug {
  width: 500px;
  height: 600px;
  padding: 20px;
  position: fixed;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  background-color: rgba($black, 0.6);
  z-index: 9999;
  pointer-events: none;
  border-radius: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  word-break: break-all;

  &.canTouch {
    pointer-events: initial;
  }

  &-action {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    border-radius: 50%;
    background-color: rgba($black, 0.6);
    z-index: 9999;
    cursor: pointer;
    top: 20px;

    &.touch {
      left: 20px;
    }

    &.close {
      left: 60px;
    }

    &.trend {
      left: 100px;
    }

    &-icon {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      fill: $gray-very-light;

      &.close {
        width: 20px;
        height: 20px;
      }

      & svg {
        width: 100%;
        height: 100%;
      }
    }
  }

  @include mobile {
    width: 320px;
    height: 400px;
  }
}
