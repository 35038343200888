@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    width: 80px;
    height: 80px;
    background-color: rgb(0, 0, 0, 0.4);
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-middle {
      display: flex;
      width: 40px;
    }
  }

  &__left-circle {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 100%;
  }

  &__right-circle {
    width: 20px;
    height: 20px;
    background-color: $pink-main;
    border-radius: 100%;
  }
}
