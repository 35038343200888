@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.header {
  width: 100%;
  height: $header-height;
  z-index: $header-index;
  background-color: white;
  position: fixed;
  top: 0px;
  transition: 0.2s;

  @include tablet {
    height: $header-mobile-height;
  }

  @include mobile {
    height: $header-mobile-height;
  }

  &__top {
    @include Body-Regular-S;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: $header-tip;
    background-color: $black;
    color: $white;
    padding: 2px;
    fill: $white;
    z-index: -1;

    & svg {
      fill: $white;
    }

    @include mobile {
      @include Body-Regular-XS;
    }

    > :not(:last-child) {
      margin-right: 8px;
    }
  }

  &__center {
    display: flex;
    align-items: center;
    width: 100%;
    height: $header-height;
    box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, 0.15);
    background: linear-gradient(90deg, #32297e 0%, #7e0089 9.95%, #ff3c78 32.08%);
    transition: all 0.2s linear;
    transform: translateY(-$header-tip);
    z-index: 1;
    position: relative;

    &--pin-to-top {
      transform: translateY(0);
    }

    &--plain {
      @include tablet {
        background: white;

        & svg {
          fill: $black;
        }
      }

      @include mobile {
        background: white;

        & svg {
          fill: $black;
        }
      }
    }

    @include tablet {
      height: $header-mobile-height;
      box-shadow: initial;
    }

    @include mobile {
      height: $header-mobile-height;
      box-shadow: initial;
    }
  }

  &__bottom {
    transition: all 0.2s linear;
    transform: translateY(-$header-tip);
    height: $header-mobile-bottom-height;
    box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, 0.15);

    &--pin-to-center {
      transform: translateY(0);
    }
  }

  &__tip {
    @include Body-Regular-S;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: $header-tip;
    background-color: $black;
    color: $white;
    padding: 2px;
    fill: $white;
    overflow: hidden;

    & svg {
      fill: $white;
    }

    &-icon {
      margin-right: 6px;
      display: inline-flex;
      align-items: center;
    }
  }

  &.white {
    background: $white;
    box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, 0.15);
  }

  &-logo {
    fill: $black;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 140px;
    height: 52px;

    @include tablet {
      width: 61px;
      height: 38px;
      margin-left: 20px;
    }

    @include mobile {
      width: 61px;
      height: 38px;
      margin-left: 20px;
    }
    &.hidden {
      display: none;
    }
  }

  &__home-page-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 30px;
  }

  &__home-page-tab {
    @include Subtitle-Bold-M;
    width: 80px;
    height: 100%;
    position: relative;

    &:not(:first-child) {
      margin-left: 40px;
    }

    &-content {
      display: flex;
      align-items: center;
      width: 100%;

      &-icon {
        margin-right: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 24px;
        height: 24px;
        opacity: 0;

        &--active {
          opacity: 1;
        }
      }

      &-text {
      }
    }

    &-background {
      width: 100%;
      height: 100%;
      position: relative;
      transition: all 0.3s ease-out;
      width: 100%;
      height: 100%;
      background: transparent;
      opacity: 0;
      color: white;

      &--selected {
      }
    }

    &-text {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      cursor: pointer;
      @include Subtitle-Bold-M;

      &--selected {
      }
    }

    &:hover {
    }

    &:hover {
    }
  }

  &__search {
    width: 100%;
    height: 100%;

    &--outlined {
      height: 46px;
      border-radius: 100px;
      border: 1px solid #ff85ab;
      background: rgba(255, 255, 255, 0.06);

      @include tablet {
        height: 100%;
        border: none;
        background: initial;
      }

      @include mobile {
        height: 100%;
        border: none;
        background: initial;
      }
    }
  }

  &__link-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    padding: 6px;
    width: 440px;
    height: 54px;
    border-radius: 100px;
    background-color: $white;
    margin: 0 10px;

    @include mobile {
      width: 166px;
      height: 44px;
    }

    > :not(:last-child) {
      margin-right: 6px;
    }

    &-link {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 100px;
      background: $gray-very-light;
      color: $pink-main;
      @include Body-Bold-M;
      transition: 0.3s;
      // mobile don't hover

      &:hover {
      }

      &.active {
        background: linear-gradient(180deg, #ff3c78 0%, #ff1a60 99.12%);
        color: $white;
      }
    }
  }

  &-action {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;

    @include tablet {
      border-left: initial;
    }

    @include mobile {
      border-left: initial;
    }

    & > * {
      height: 100%;
      display: inline-flex;
      align-items: center;
    }

    & > * {
      padding: 0 16px;

      @include tablet {
        padding: 0 6px;
      }

      @include mobile {
        padding: 0 6px;
      }
    }

    &-mobile-search {
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;

      &:hover {
        & svg {
          fill: $gray;
        }
      }

      &-wraper {
        width: 38px;
        height: 38px;
        padding: 4px;
      }
    }

    &-shopbag {
      position: relative;
      width: 70px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      @include tablet {
        width: 50px;
      }

      @include mobile {
        width: 50px;
      }

      &--has-not-login {
        @include desktop-only {
          &:hover {
            & .header-action-shopbag-icon {
              & svg {
                stroke: $pink-background;
              }
            }
          }
        }
      }

      &-wrapper {
        width: 38px;
        height: 38px;
        padding: 4px;
        position: relative;
      }

      &-icon {
        width: 100%;
        height: 100%;

        & svg {
          width: 100%;
          height: 100%;
          stroke: white;
        }

        &--plain {
          @include tablet {
            & svg {
              fill: $black;
            }
          }

          @include mobile {
            & svg {
              fill: $black;
            }
          }
        }
      }

      &-number {
        position: absolute;
        min-width: 16px;
        min-height: 16px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'DM Sans';
        font-weight: 500;
        font-size: 8px;
        line-height: 130%;
        top: 3px;
        right: 0;
        background-color: white;
        color: $pink-main;

        &--plain {
          @include tablet {
            background-color: $pink-main;
            color: white;
          }

          @include mobile {
            background-color: $pink-main;
            color: white;
          }
        }
      }
    }

    &-language {
      padding: 0 20px;
      display: flex;
      gap: 10px;
      width: auto;
      cursor: pointer;

      &-icon {
        width: 20px;
        height: 20px;

        & svg {
          width: 100%;
          height: 100%;
          fill: white;
        }
      }
      &-text {
        @include Body-Regular-S;
        color: white;
        white-space: nowrap;
      }
    }

    &-login {
      cursor: pointer;

      &--has-not-login {
        @include desktop-only {
          &:hover {
            & .header-action-login-wraper {
              & svg {
                fill: $pink-background;
              }
            }
          }
        }
      }

      &--login {
        @include desktop-only {
          &:hover {
            & .header-action-login-wraper {
              background-color: $pink-background;
            }
          }
        }
      }

      &-wraper {
        width: 38px;
        height: 38px;
        padding: 6px;
        border-radius: 100px;
        transition: all 0.2s linear;

        &--login {
          background-color: $pink-light;
        }

        & svg {
          fill: white;
        }
      }
    }

    &-burger {
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: #f22463;

      &-wraper {
        width: 38px;
        height: 38px;
        padding: 4px;

        & svg {
          fill: white;
        }

        &--plain {
          @include tablet {
            & svg {
              fill: $black;
            }
          }

          @include mobile {
            & svg {
              fill: $black;
            }
          }
        }
      }

      &--plain {
        @include tablet {
          background-color: #f2f2f2;
        }

        @include mobile {
          background-color: #f2f2f2;
        }
      }
    }

    &-avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      overflow: hidden;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 0px 8px 16px -8px rgba(0, 0, 0, 0.15);
    background-color: white;
    border: 1px solid $gray-background;
    padding: 20px;

    > :not(:last-child) {
      margin-bottom: 14px;
    }

    &.center {
      align-items: center;
    }

    &-item {
      color: $black;
      @include Body-Regular-S;
      cursor: pointer;

      &:hover {
        color: $pink-main;
        fill: $pink-main;
      }
    }
  }

  &__mobile-home-page-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: white;
  }

  &__mobile-home-page-tab {
    @include Body-Bold-M;
    color: white;
    width: 50%;
    height: 100%;
    position: relative;

    &:not(:first-child) {
      border-left: 1px solid $gray-feather;
    }

    &--selected {
      background: linear-gradient(103deg, #6458c4 -68.21%, #be42b5 -13.75%, #ff3c78 37.51%, #ff6b4d 86.64%, #ff9428 136.83%);

      &::after {
        color: white;
        background: initial;
      }
    }

    &-placeholder {
      width: 100%;
      position: absolute;

      background: linear-gradient(103deg, #6458c4 -68.21%, #be42b5 -13.75%, #ff3c78 37.51%, #ff6b4d 86.64%, #ff9428 136.83%);
      background: -webkit-linear-gradient(103deg, #6458c4 -68.21%, #be42b5 -13.75%, #ff3c78 37.51%, #ff6b4d 86.64%, #ff9428 136.83%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @include Body-Bold-M;
      left: 0;
      right: 0;
      bottom: 0;
      width: fit-content;
      margin: 0 auto;
      top: 50%;
      transform: translateY(-50%);

      &--selected {
        background: $white;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        &::after {
          color: white;
          background: initial;
        }
      }
    }
  }

  &__dropdown-menu {
    padding: 0;
    position: absolute;
    top: 70px;
    right: 0px;
    width: 100%;
    height: calc(100vh - 70px);
    overflow-x: hidden;
    overflow-y: auto;
    background: rgba(8, 8, 8, 0.4);
    transition: all 0.2s ease-out;
    pointer-events: none;
    opacity: 0;
    cursor: pointer;

    &--open {
      pointer-events: initial;
      opacity: 1;
    }

    @include tablet {
      top: 50px;
      background-color: white;
    }

    @include mobile {
      top: 50px;
      background-color: white;
    }

    &--with-header-tip {
      height: calc(100vh - 50px - 30px);
    }

    &--without-header-tip {
      height: calc(100vh - 50px);
    }

    &-list {
      background-color: white;
      width: 100%;
      height: 100%;
      max-width: 320px;
      position: absolute;
      right: 0;
      top: 0;
      transform: translateX(100%);
      transition: all 0.4s ease-out;
      border-top: 1px solid $gray-feather;
      cursor: initial;

      &--open {
        transform: translateX(0);
      }

      @include tablet {
        max-width: initial;
        height: auto;
      }

      @include mobile {
        max-width: initial;
        height: auto;
      }
    }

    &-item {
      @include Body-Regular-M;
      color: $gray-dim;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid $gray-feather;
      width: 100%;
      height: 60px;

      &:hover {
        color: $black;

        @include tablet {
          color: $gray-dim;
        }

        @include mobile {
          color: $gray-dim;
        }
      }

      &--login {
        background-color: #f9f9f9;
      }
    }

    &-mobile-list-major {
      border-bottom: 1px solid $gray-feather;
    }

    &-mobile-item {
      @include Body-Regular-M;
      color: $gray-dim;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 60px;
      background-color: #f9f9f9;
    }

    &-mobile-home-page-tabs {
      display: flex;
      width: 100%;
      height: 100%;
    }

    &-mobile-home-page-tab {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 100%;
      position: relative;
      color: white;

      &:last-child {
        border-left: 1px solid $gray-feather;
      }

      &-placeholder {
        width: 100%;
        position: absolute;
        background: linear-gradient(103deg, #6458c4 -68.21%, #be42b5 -13.75%, #ff3c78 37.51%, #ff6b4d 86.64%, #ff9428 136.83%);
        background: -webkit-linear-gradient(103deg, #6458c4 -68.21%, #be42b5 -13.75%, #ff3c78 37.51%, #ff6b4d 86.64%, #ff9428 136.83%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        min-height: auto;
        @include Body-Bold-M;
        left: 0;
        right: 0;
        bottom: 0;
        width: fit-content;
        margin: 0 auto;
        top: calc(50% - 10px);
      }
    }

    &-mobile-login {
      display: flex;
      align-items: center;

      &-icon {
        margin-right: 10px;
        width: 38px;
        height: 38px;
        padding: 4px;
        border-radius: 100px;

        &--login {
          background-color: $gray-very-light;
          & svg {
            fill: $gray;
          }
        }
      }

      &-username {
      }
    }
  }

  &__social-media {
    margin: 20px 0;

    &-title {
      @include Body-Regular-M;
      color: $gray-dim;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }

    &-list {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-item {
      display: flex;
      width: 30px;
      height: 30px;

      &:not(:first-child) {
        margin-left: 30px;
      }
    }
  }
}

.container {
  @include container-hf;
  height: 100%;
}

.PopoverContent {
  min-width: 100px;
  z-index: $header-index + 1;
  padding-top: 6px;
  background-color: transparent;
}
