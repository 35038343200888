:root {
  --primary-color: #ff3c78;
  --secondary-color: black;
  --insight-background-color: linear-gradient(97deg, #ff3c78 0%, #ff6b4d 100%), linear-gradient(180deg, #64be3c 0%, #43a816 100%);
  --chat-room-placard-background-color: rgba(8, 8, 8, 0.06);
  --chat-room-product-list-price-color: #ff3c78;
  --chat-room-product-list-discount-rate-tag-color: #ff3c78;
  --chat-room-product-list-discount-rate-tag-background-color: #ffe2eb;
  --chat-room-input-icon-color: #ff3c78;

  --chat-room-message-list-host-title-background-color: #ff3c78;
  --chat-room-message-list-user-title-color: #ff9428;
  --chat-room-message-list-user-title-color-mobile: #ffdebc;
  --chat-room-message-list-user-purchased-color: #ff6b4d;
  --chat-room-message-list-user-entered-color: #64be3c;

  --chat-room-mic-request-background-color-1: #ff9428;
  --chat-room-mic-request-background-color-2: #ff3c78;
  --chat-room-mic-wait-background-color-1: #be42b5;
  --chat-room-mic-wait-background-color-2: #6458c4;
}

[data-theme='strawberry'] {
  --primary-color: #ff3c78;
  --secondary-color: black;
  --insight-background-color: linear-gradient(97deg, #ff3c78 0%, #ff6b4d 100%), linear-gradient(180deg, #64be3c 0%, #43a816 100%);
  --chat-room-placard-background-color: rgba(8, 8, 8, 0.06);
  --chat-room-product-list-price-color: #ff3c78;
  --chat-room-product-list-discount-rate-tag-color: #ff3c78;
  --chat-room-product-list-discount-rate-tag-background-color: #ffe2eb;
  --chat-room-input-icon-color: #ff3c78;
}

[data-theme='pchome'] {
  --primary-color: #ea1717;
  --secondary-color: #f42727;
  --insight-background-color: #ea1717;
  --chat-room-placard-background-color: #ea17171a;
  --chat-room-product-list-price-color: #ea1717;
  --chat-room-product-list-discount-rate-tag-color: #ea1717;
  --chat-room-product-list-discount-rate-tag-background-color: #ea17171a;
  --chat-room-input-icon-color: #ea1717;

  --chat-room-message-list-host-title-background-color: #ea1717;
  --chat-room-message-list-user-title-color: #57a7ff;
  --chat-room-message-list-user-title-color-mobile: #57a7ff;
  --chat-room-message-list-user-purchased-color: #0146d4;
  --chat-room-message-list-user-entered-color: #64be3c;

  --chat-room-mic-request-background-color-1: #ff3c78;
  --chat-room-mic-request-background-color-2: #ea1717;
  --chat-room-mic-wait-background-color-1: #448fe4;
  --chat-room-mic-wait-background-color-2: #6458c4;
}

// SHOP 和 Live Mall Club 共用的樣式需要抽 theme
// 沒有共用的可以不抽
[data-theme='livemallclub'] {
  --primary-color: #e50e23;
  --secondary-color: #faae4a;
}
