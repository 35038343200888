/**
 * Reset box-sizing
 */

/* https://-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */

html {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
   * Document
   */

body {
  height: inherit;
  font-family: 'DM Sans', 'Roboto', 'Noto Sans TC', -apple-system, Segoe UI, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

  line-height: 1.5;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

/**
   * Status
   */

:disabled,
[disabled] {
  cursor: not-allowed;
}

/**
   * Link
   */

a {
  background-color: transparent; /* Remove the gray background on active links in IE 10. */
  -webkit-text-decoration-skip: objects; /* Remove gaps in links underline in iOS 8+ and Safari 8+. */

  &,
  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
    color: initial;
  }
}

/**
   * Form
   */

/*
   * Clear `clear` button on input with 'password' type in IE
   * https://developer.mozilla.org/en-US/docs/Web/CSS/::-ms-clear
   * https://developer.mozilla.org/en-US/docs/Web/CSS/::-ms-reveal
   */

input {
  font-family: inherit;

  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }

  &:hover,
  &:focus {
    outline: none;
  }
}

/**
   * Reset button
   */

button {
  cursor: pointer;
  padding: 0;
  font-family: inherit;
  background: transparent;
  border: none;

  &:hover,
  &:focus {
    outline: none;
  }
}

/* Turn off textarea resize in Chrome */

textarea {
  font-family: inherit;
  resize: none;

  &:hover,
  &:focus {
    outline: none;
  }
}

/**
   * Image
   */

/* Remove default margin */

figure {
  margin: 0;
}

/* https://-tricks.com/almanac/properties/v/vertical-align/ */

img {
  border: none;
  vertical-align: middle;
}

/**
   * Typography
   */

/* Remove defalut margin / padding / list-style */

ul,
ol,
dl {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
}

#__next {
  height: inherit;
  --toastify-toast-min-height: 50px;
  --toastify-text-color-light: $white;
  --toastify-z-index: 10000;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: $gray-light;
  border-radius: 100px;
}

::-webkit-scrollbar-track {
  background-color: $gray-feather;
  border-radius: 100px;
}

// react-toastify 官方表示直接 class override
// reference https://fkhadra.github.io/react-toastify/how-to-style
.Toastify {
  position: fixed;
  z-index: 10002;
}

.Toastify__toast-container {
  top: 92px !important;
  @include mobile {
    top: 90px !important;
  }
}
.Toastify__toast-body {
  padding: auto 8px !important;
}

.Toastify__toast-theme--light {
  background-color: rgba(08, 08, 08, 0.6) !important;
  @include Body-Regular-S;
  border-radius: 10px !important;
}

.vjs-loading-spinner {
  display: none !important;
}
.hidden {
  display: none;
}
