@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.header {
  width: 100%;
  height: $header-height;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  position: fixed;
  top: 0px;
  background-color: var(--primary-color);
  z-index: $header-index;

  @include tablet {
    padding: 0px 10px;
    height: $header-mobile-height;
  }

  @include mobile {
    padding: 0px 10px;
    height: $header-mobile-height;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &-logo {
    width: 180px;
    height: 33px;
    position: relative;

    @include tablet {
      width: 140px;
      height: 25px;
    }

    @include mobile {
      width: 140px;
      height: 25px;
    }

    & svg {
      width: 100%;
      height: 100%;
    }
  }

  &__code {
    padding: 4px 10px 4px 10px;
    border-radius: 100px;
    background-color: #ffffff33;
    color: #ffffff99;

    @include tablet {
      font-size: 12px;
    }

    @include mobile {
      font-size: 12px;
    }
  }
}
