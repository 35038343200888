@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.header {
  width: 100%;
  height: $header-height;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  position: fixed;
  top: 0px;
  background-color: $white;
  z-index: $header-index;

  @include tablet {
    height: $header-mobile-height;
  }

  &-logo {
    width: 166px;
    height: 56px;
    position: relative;

    @include tablet {
      width: 120px;
      height: 40px;
    }

    & svg {
      width: 100%;
      height: 100%;
    }
  }
}
