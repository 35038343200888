@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.menu-item {
  @include Body-Regular-M;
  color: $gray-dim;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $gray-feather;
  width: 100%;
  height: 60px;
  gap: 6px;

  &:hover {
    color: $black;

    @include tablet {
      color: $gray-dim;
    }

    @include mobile {
      color: $gray-dim;
    }
  }
  &--icon {
    width: 24px;
    height: 24px;
    & svg {
      width: 100%;
      height: 100%;
    }
  }

  &--login {
    background-color: #f9f9f9;
  }
}
