.ant-picker-dropdown {
  z-index: 9999;

  * {
    vertical-align: baseline;
  }
}

.ant-picker-input > input {
  font-size: 14px;

  @include mobile {
    font-size: 16px;
  }
}

.ant-picker-cell-disabled {
  cursor: not-allowed;

  &::before {
    background: transparent;
  }
}

.ant-picker {
  padding: 0px;
}

.ant-picker-panel-container {
  width: 350px;
  border-radius: 12px;

  th,
  td {
    font-size: 16px;
  }
}

.ant-picker-decade-panel {
  width: 100%;

  .ant-picker-content {
    th,
    td {
      width: 108px;
      height: 108px;
    }

    .ant-picker-cell::before {
      width: 108px;
      height: 108px;
    }

    .ant-picker-cell .ant-picker-cell-inner {
      width: 108px;
      height: 108px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 0px auto;
    }
  }
}

.ant-picker-year-panel,
.ant-picker-month-panel {
  width: 100%;

  .ant-picker-content {
    th,
    td {
      width: 56px;
      height: 56px;
    }

    .ant-picker-cell::before {
      width: 56px;
      height: 56px;
    }

    .ant-picker-cell .ant-picker-cell-inner {
      width: 56px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 0px auto;
    }
  }
}

.ant-picker-date-panel {
  width: 100%;

  .ant-picker-content {
    width: 100%;

    th {
      width: 28px;
      height: 28px;
    }

    td {
      width: 46px;
      height: 30px;
    }

    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(
        .ant-picker-cell-range-hover-start
      ):not(.ant-picker-cell-range-hover-end)
      .ant-picker-cell-inner {
      width: 28px;
      height: 28px;
    }

    .ant-picker-cell::before {
      width: 28px;
      height: 28px;
    }

    .ant-picker-cell .ant-picker-cell-inner {
      width: 28px;
      height: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 0px auto;
    }

    .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
      width: 28px;
      height: 28px;
      border-radius: 50%;

      &::before {
        width: 28px;
        height: 28px;
        border-radius: 50%;
      }
    }
  }
}

// 大人 antd datepicker 顏色 // TODO: 要再確定顏色
.app-theme {
  .ant-picker-header-view button:hover {
    color: $pink-main;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: $pink-main;
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(
      .ant-picker-cell-range-hover-start
    ):not(.ant-picker-cell-range-hover-end)
    .ant-picker-cell-inner {
    background: $pink-main;
    border-radius: 50%;
    color: $white;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner {
    &::before {
      border: 1px solid $pink-main;
    }
  }
}
