@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.layout {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: $header-height;
  background-color: $gray-very-light;

  @include tablet {
    padding-top: $header-mobile-height;
  }

  &-body {
    min-height: calc(100% - $header-height);
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;

    @include mobile {
      min-height: calc(100% - $header-mobile-height);
    }
  }
}
