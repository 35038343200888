@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
$themes: (
  LOG: (
    color: $white,
  ),
  ERROR: (
    color: $red-error,
  ),
);

.item {
  @each $theme, $styles in $themes {
    &[data-type='#{$theme}'] {
      color: map-get($styles, color);
    }
  }

  &:not(:last-of-type) {
    border-bottom: 1px solid $gray-very-light;
  }

  &-data {
    &-item {
      width: 100%;
      &:not(:last-of-type) {
        margin-right: 2px;
      }
    }
  }
}
