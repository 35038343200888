@import "./src/styles/_variables.scss";@import "./src/styles/mixins/mixins.scss";
.input-wrapper {
  &-input {
    width: 100%;
    background-color: transparent;
    border: none;
    font-size: 14px;
    font-weight: 500;
    color: $black;
    padding: 0px 0px;

    &[data-is-password='true'] {
      padding-right: 68px;
    }

    &[data-has-error='true'] {
      color: $red-error;
    }

    caret-color: $gray-dim;

    @include mobile {
      font-size: 14px;
    }

    &:hover,
    &:focus {
      border: none;
      box-shadow: none;
    }

    &::placeholder {
      font-weight: 400;
      color: $gray-light;
    }
  }

  &-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 4px;
    top: -8px;
    cursor: pointer;

    &-image {
      width: 100%;
      height: 100%;
      pointer-events: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -o-user-select: none;
      user-select: none;
    }
  }
}
